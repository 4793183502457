.editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);
  background-color: white;
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);
  /* Set vertical boundaries for the document editor. */
  min-height: 400px;
  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
  z-index: 10;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  /* not accessible, should think of a better solution here */
  outline: none;
  border: none;
  box-shadow: none;
}

.mention {
  /* for unselected comment mentions */
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}

.sidebar {
}

.toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 11;
  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);
  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Adjust the look of the toolbar inside the container. */
.toolbar .ck.ck-toolbar {
  /* was just .ck-toolbar? */
  border: 0;
  border-radius: 0;
}
